export default () => ({
  required: {
    required: true,
    message: useT('rules.requiredField'),
    trigger: 'submit',
  },
  email: {
    type: 'email',
    message: useT('rules.emailFormat'),
    trigger: 'submit',
  },
  url: {
    type: 'url',
    message: useT('rules.invalidUrl'),
    trigger: 'submit',
  },
  password: [
    { required: true, message: useT('rules.fillInPassword'), trigger: 'submit' },
    { strength: 'minLength', regexp: true, pattern: passwordStrength.minLength, message: useT('rules.passwordTooShort'), trigger: 'submit' },
    { strength: 'uppercaseOrNumber', regexp: true, pattern: passwordStrength.uppercaseOrNumber, message: useT('rules.passwordNoCapitalOrNumber'), trigger: 'submit' },
    {
      validator: (rule, value) => passwordStrength.isStrong(value),
      message: useT('rules.weakPassword'),
      trigger: 'submit',
    },
  ],
  passwordConfirm: (confirmValue) => {
    return [{
      validator: (rule, value, callback) => {
        if (value === '') {
          callback(new Error(useT('rules.fillInPasswordAgain')));
        } else if (value !== confirmValue.value) {
          callback(new Error(useT('rules.passwordDoesNotMatch')));
        } else {
          callback();
        }
      },
      trigger: 'blur',
    }];
  },
  startDate: (endDate) => {
    return {
      validator: (rule, value) => {
        return value ? useDayjs(value).isBefore(endDate.value) : true;
      },
      message: useT('rules.startTimeAfterEndTime'),
      trigger: 'submit',
    };
  },
  endDate: (startDate) => {
    return {
      validator: (rule, value) => {
        return value ? useDayjs(value).isAfter(startDate.value) : true;
      },
      message: useT('rules.startTimeAfterEndTime'),
    };
  },
});
